import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/payment/:orderId',
    name: 'Payment',
    component: () => import( '../views/PaymentAfter.vue'),
    children: [
      {
        path: '',
        name: 'PaymentContainer',
        // component: () => import( '../components/RightContainer.vue'),
        component: () => import( '../components/rightComponent/RightContainer.vue'),
      },
      {
        path: 'expired',
        name: 'expired-payment',
        // component: () => import( '../components/Expired.vue')
        component: () => import('../components/StatusPayment/statusPayment.vue')
      },
      {
        path: 'cancel',
        name: 'cancel-payment',
        // component: () => import( '../components/CancelTransaksi.vue'),
        component: () => import('../components/StatusPayment/statusPayment.vue')
      },
      {
        path: 'success',
        name: 'success-payment',
        component: () => import('../components/StatusPayment/statusPayment.vue')
        // component: () => import( '../components/Success.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'AllSet',
    component: () =>
        import('../views/PaymentAfter.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=> {
    if((to.name == 'expired-payment' && !store.state.isExpired)
     || (to.name == 'success-payment' && !store.state.isSuccess)
     || (to.name == 'cancel-payment' && !store.state.isCancel)
    ) {
        const orderId = to.params.orderId
        next({path: `/payment/${orderId}`, replace: true})
    }
    next()
})

export default router
