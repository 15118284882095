<template>
  <div class="container-payment">
    <span class="dev-test" v-if="devlopmentType">DEV</span>
    <router-view />

    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog center" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="text-center">
              <template v-if="modalType == 'error'">
                <div class="negative danger">
                  <img src="@/assets/img/cancel-payment.png" alt="" />
                  <p>
                    Error
                    {{
                      errorMessage && errorMessage.status
                        ? errorMessage.status
                        : ""
                    }}
                  </p>
                  <p>
                    {{
                      errorMessage && errorMessage.status
                        ? errorMessage.title
                        : ""
                    }}
                  </p>

                  <button class="btn caredokter-button" @click="reload">
                    Try Again
                  </button>
                </div>
              </template>
              <template v-else-if="modalType == 'question'">
                <div>
                  <img src="@/assets/img/Question.png" alt="" />
                  <div class="row">
                    <div class="col-12">
                      <span class="title-modal">Batalkan Pembayaran</span>
                      <p class="title-body">
                        Apakah Anda yakin ingin membatalkan pembayaran?
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <button
                        class="btn caredokter-button mr-2"
                        @click="cancel"
                        :disabled="isLoadingCharge"
                      >
                        <template v-if="isLoadingCharge">
                          <label class="m-0">
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </label>
                        </template>
                        <template v-else> Ya, saya yakin </template>
                      </button>
                      <button
                        class="btn btn-outline-caredokter ml-2"
                        data-dismiss="modal"
                        v-if="!isLoadingCharge"
                      >
                        Tidak, Kembali
                      </button>
                      
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState({
      errorHandle: (state) => state.errorHandle,
      modalType: (state) => state.modalType,
      isLoadingCharge: (state) => state.isLoadingCharge,
    }),
  },
  methods: {
    reload() {
      location.reload();
    },
    ...mapActions({
      cancel: "CANCEL_PAYMENT",
    }),
  },
  data() {
    return {
      devlopmentType: process.env.VUE_APP_DEVELOPMENT,
    };
  },
};
</script>
<style>
.fs-18 {
  font-size: 18px;
  font-weight: 400;
}
.fs-20 {
  font-size: 20px;
  font-weight: 600;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.dev-test {
  position: absolute;
  right: -41px;
  width: 140px;
  background-color: blue;
  font-size: 16px;
  color: white;
  padding: 5px 40px;
  transform: rotate(35deg);
  top: 7px;
  text-align: center;
  font-weight: bold;
  z-index: 10000;
}
</style>
