import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './../router'
import $ from 'jquery'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        parentName: '',
        isChildren: false,
        detailTransaksi: {},
        listPayment: [],
        listPaymentMobile: [],
        isGetData: true,
        isButtonShowDetail: true,
        loadingInfo: true,
        loadingPayment: true,
        showIntroduction: false,
        showIntheList: [],
        historySNAP: [],
        detailMethodPayment: {},
        isChangeMethodPayment: false,
        isRunMobile: false,
        isComplete: false,
        errorHandle: '',
        isExpired: false,
        isSuccess: false,
        isCancel: false,
        isAfterCharge: false,
        btnNameFloat: '',
        btnNameFloatHelp: '',
        statusPayment: '',
        deliveryInfo: {},
        listChildData: {},
        isLoadingCharge: false,
        modalType: '',
        isOpenFloat: false
    },
    mutations: {
        SET_IS_OPEN_FLOAT(state, res){
            state.isOpenFloat = res
        },
        SET_MODAL_TYPE(state, res){
            state.modalType = res
        },
        SET_IS_LOADING_CHARGE(state, res){
            state.isLoadingCharge = res
        },
        SET_LIST_CHILD_DATA(state, res){
            state.listChildData = res
        },
        SET_IS_CHILDREN(state, res){
            state.isChildren = res
        },
        SET_LIST_PAYMENT_MOBILE(state, res) {
            state.listPaymentMobile = res
        },
        SET_DELIVERY_INFO(state, res) {
            state.deliveryInfo = res
        },
        SET_STATUS_PAYMENT(state, res) {
            state.statusPayment = res
        },
        SET_BTN_NAME_FLOAT(state, res) {
            state.btnNameFloat = res
        },
        SET_IS_AFTER_CHARGE(state,res) {
            state.isAfterCharge = res
        },
        SET_IS_EXPIRED(state, res) {
            state.isExpired = res
        },
        SET_IS_CANCEL(state, res) {
            state.isCancel = res
        },
        SET_IS_SUCCESS(state, res) {
            state.isSuccess = res
        },
        SET_ERROR_HANDLE(state, res) {
            state.errorHandle = res
        },
        SET_IS_SHOW_DEFAULT(state, res) {
            if(!res) {
                state.btnNameFloatHelp = state.btnNameFloat
                state.btnNameFloat = 'Pilih Pembayaran'
                // console.log(state.customerDetail)
                if(state.customerDetail && state.customerDetail.paymentTransactionStatus == 'PAID') {
                    state.btnNameFloat = 'Status'
                }
                
            } else {
                state.btnNameFloat = state.btnNameFloatHelp
            }
            state.isButtonShowDetail = res
        },
        SET_IS_COMPLETE(state, res) {
            state.isComplete = res
        },
        SET_IS_RUN_MOBILE(state, res) {
            state.isRunMobile = res
        },
        SET_CHANGE_METHOD_PAYMENT(state, res) {
            state.isChangeMethodPayment = res
        },
        SET_IS_GET_DATA(state, res) {
            state.isGetData = res
        },
        SET_DETAIL_TRANSACTION(state, res) {
            if (res.itemDetail) {
                try {
                    res.itemDetail = JSON.parse(res.itemDetail)
                }
                catch {
                    // error
                    res.itemDetail = []
                }
            }
            if(res.customerDetail && res.customerDetail != "null") {
                res.customerDetail = JSON.parse(res.customerDetail)
            } else {
                res.customerDetail = ''
            }
            // console.log('seeett', res)
            state.detailTransaksi = res
            document.title = 'Payment ' + res.snapName
        },
        SET_LIST_PAYMENT(state, res) {
            state.listPayment = res
        },
        SET_LOADING_PAYMENT(state, res) {
            state.loadingPayment = res
        },
        SET_LOADING_INFO(state, res) {
            state.loadingInfo = res
        },
        SET_SHOW_INTRODUCTION(state, res) {
            state.showIntroduction = res
        },
        SET_HISTORY_SNAP(state, res) {
            state.historySNAP.push(res)
        },
        SET_HISTORY_TO_NULL(state) {
            state.historySNAP = []
        },
        SET_SHOW_IN_THE_LIST(state, res) {
            const strList = JSON.stringify(res)
            state.showIntheList = JSON.parse(strList)
        },
        SET_DETAIL_METHOD_PAYMENT(state, res) {
            state.detailMethodPayment = res
        }
    },
    actions: {
        async GET_PAYMENT_DETAIL({ state, commit, dispatch }, orderId) {
            commit('SET_LOADING_INFO', true)
            let isMobile = false
                if (/Mobi|Android/i.test(navigator.userAgent) || document.documentElement.clientWidth < 1110) {
                    // mobile!
                    isMobile = true
                    commit('SET_IS_RUN_MOBILE', isMobile)
                }
            const url = `${process.env.VUE_APP_BASE_URL}/mandaya-payment/api/payment-transactions/${orderId}/order`
            // console.log(url)
            try {
                const response = await axios.get(url)
                commit('SET_DETAIL_TRANSACTION', response.data)
                commit('SET_LOADING_INFO', false)
                dispatch('GET_METHOD_PAYMENT', { data: response.data, orderId })
                commit('SET_IS_GET_DATA', true)

                if(response.data.payment && response.data.payment.deepLink && state.isAfterCharge) {
                    window.open(response.data.payment.deepLink, '_blank')
                    commit('SET_IS_AFTER_CHARGE', false)
                }
            } catch {
                commit('SET_IS_GET_DATA', false)
            }
            commit('SET_IS_LOADING_CHARGE', false)
            if(state.isOpenFloat) {
                console.log('masukkkk')
                dispatch('CHEVRON_ACTION')
            }
            $('#exampleModalCenter').modal('hide')
        },
        async GET_METHOD_PAYMENT({ state, commit, dispatch }, { data, orderId }) {
            commit('SET_LOADING_PAYMENT', true)

            const url = `${process.env.VUE_APP_BASE_URL}/mandaya-payment/api/client-payment-methods/${orderId}/selected`

            try {
                let methodPayment = []
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).catch(error => {
                    // console.log('method Payment',error.response)
                    const message = error.response.data && error.response.data.message? error.response.data : 'Snap Not Found' 
                    commit('SET_ERROR_HANDLE', message)
                    commit('SET_MODAL_TYPE', 'error')
                    $('#exampleModalCenter').modal()
                })
                commit('SET_IS_GET_DATA', true)
                commit('SET_IS_COMPLETE', true)
                const responseData = response.data
                let isMobile = false
                
                if (/Mobi|Android/i.test(navigator.userAgent) || document.documentElement.clientWidth < 1110) {
                    // mobile!
                    isMobile = true
                    dispatch('GET_LIST_PAYMENT_MOBILE', response)
                }
                commit('SET_IS_RUN_MOBILE', isMobile)

                for (let i = 0; i < responseData.length; i++) {
                    let checkMobile = false
                    // console.log('masukk sini', responseData[i])
                    try {
                        responseData[i].paymentMethod.instruction = JSON.parse(responseData[i].paymentMethod.instruction)
                        if (responseData[i].paymentMethod.instruction.mobile && !isMobile && responseData[i].paymentMethod.instruction.mobile != "false") {
                            checkMobile = true
                        }
                    } catch {
                        // apabila tidak memiliki json instruction
                    }
                    if (!checkMobile) {
                        methodPayment.push(responseData[i])
                    }
                }
                // commit('SET_LIST_PAYMENT', response.data)
                const listWithOrder = methodPayment.sort((a, b) => a.position - b.position)
                commit('SET_LIST_PAYMENT', listWithOrder)
                // console.log('1', data.paymentTransactionStatus)
                if (data.paymentTransactionStatus == 'CREATED' || state.isChangeMethodPayment) {
                    dispatch('CHECK_HAS_CHILD', '')
                } else if (data.paymentTransactionStatus == 'UNDERPAYMENT') {
                    const idPayment = data.payment.paymentMethodCode
                    const indexMethod = response.data.findIndex(item => item.paymentMethod.code == idPayment)
                    dispatch('CHECK_HAS_CHILD', responseData[indexMethod].paymentMethod.id)
                } else if (data.paymentTransactionStatus == 'PAID') {
                    commit('SET_IS_SUCCESS', true)
                    commit('SET_BTN_NAME_FLOAT', 'Selesai')
                    dispatch('CHECK_PAYMENT_PAID', data.payment.clientPaymentMethodId)
                    router.push({ name: 'success-payment' })
                } else if (data.paymentTransactionStatus == 'EXPIRED') {
                    commit('SET_IS_EXPIRED', true)
                    router.push({ name: 'expired-payment' })
                    commit('SET_BTN_NAME_FLOAT', 'Selesai')
                } else if (data.paymentTransactionStatus == 'CANCELLED') {
                    commit('SET_IS_CANCEL', true)
                    router.push({ name: 'cancel-payment' })
                    commit('SET_BTN_NAME_FLOAT', 'Selesai')
                }
            } catch {
                commit('SET_IS_GET_DATA', false)
                commit('SET_IS_COMPLETE', false)
            }
            commit('SET_LOADING_PAYMENT', false)
        },
        CHECK_HAS_CHILD({ state, commit }, id) {
            let listAllPayment = state.listPayment
            commit('SET_SHOW_IN_THE_LIST', [])
            if (id) {
                const idItem = listAllPayment.findIndex(item => item.paymentMethod.id == id)
                // console.log('ini', idItem)
                if (listAllPayment[idItem].paymentMethod.hasChild) {
                    // list show yang ditampilkan
                    state.parentName = listAllPayment[idItem].paymentMethod.name
                    const data = listAllPayment.filter(item => item.paymentMethod.parentId == id)
                    commit('SET_SHOW_IN_THE_LIST', data)
                    commit('SET_SHOW_INTRODUCTION', false)
                    commit('SET_BTN_NAME_FLOAT', '')
                    if(state.isRunMobile) {
                        commit('SET_IS_CHILDREN', true)
                    }
                } else {
                    const data = listAllPayment.filter(item => item.paymentMethod.id == id)
                    state.parentName = data[0].paymentMethod.name
                    commit('SET_DETAIL_METHOD_PAYMENT', data[0])
                    commit('SET_SHOW_INTRODUCTION', true)
                    commit('SET_BTN_NAME_FLOAT', 'Bayar')
                }
            } else {
                // console.log('123')
                state.parentName = 'Pilih Pembayaran'
                commit('SET_BTN_NAME_FLOAT', '')
                commit('SET_SHOW_INTRODUCTION', false)
                const data = listAllPayment.filter(item => !item.paymentMethod.parentId)
                commit('SET_SHOW_IN_THE_LIST', data)
                if(state.isRunMobile) {
                    commit('SET_IS_CHILDREN', false)
                }
            }
            // dispatch('CHECK_DOCUMENT')
        },
        CHECK_PAYMENT_PAID({state, commit}, id){
            let listAllPayment = state.listPayment
            const data = listAllPayment.filter(item => item.id == id)
            commit('SET_DETAIL_METHOD_PAYMENT', data[0])
            commit('SET_STATUS_PAYMENT', 'PAID')
        },
        SET_TO_BEFORE_ROUTE({ state, dispatch }) {
            state.historySNAP.pop()
            const lengthSnap = state.historySNAP.length

            if (lengthSnap > 0) {
                dispatch('CHECK_HAS_CHILD', state.historySNAP[lengthSnap - 1])
            } else {
                dispatch('CHECK_HAS_CHILD', '')
            }
        },
        async CHARGE_ORDER_PAYMENT({ state, commit }) {
            // console.log(state.detailMethodPayment)
            commit('SET_LOADING_PAYMENT', true)
            const detailOrder = state.detailMethodPayment
            const detailUser = state.detailTransaksi
            const code = detailOrder.paymentMethod.code
            const url = `${process.env.VUE_APP_BASE_URL}/mandaya-payment/api/payments/${detailUser.transactionOrderId}/${code}/charge`
            // console.log(url)
            if (state.isChangeMethodPayment) {
                commit('SET_CHANGE_METHOD_PAYMENT', false)
            }
            try {
                const body = {}
                await axios.post(url, body).catch(error => {
                    const message = error.response.data && error.response.data.message? error.response.data : 'Snap Not Found' 
                    commit('SET_ERROR_HANDLE', message)
                    commit('SET_MODAL_TYPE', 'error')
                    $('#exampleModalCenter').modal()
                })
                if(state.isRunMobile) {
                    commit('SET_IS_AFTER_CHARGE', true)
                }
                commit('SET_HISTORY_TO_NULL')
                // console.log(response)
            } catch {
                // 
            }
            commit('SET_LOADING_PAYMENT', false)
        },
        // eslint-disable-next-line no-unused-vars
        async CANCEL_ORDER_PAYMENT({ commit }) {
            commit('SET_MODAL_TYPE', 'question')
            $('#exampleModalCenter').modal()
        },
        async CANCEL_PAYMENT({commit, state}) {
            // commit('SET_LOADING_PAYMENT', true)
            commit('SET_IS_LOADING_CHARGE', true)
            const detailUser = state.detailTransaksi
            const url = `${process.env.VUE_APP_BASE_URL}/mandaya-payment/api/payments/${detailUser.transactionOrderId}/cancel`
            try {
                const body = {}
                 await axios.post(url, body)
                // console.log(response)
               
            } catch {
                  commit('SET_IS_LOADING_CHARGE', false)
            }
            // commit('SET_LOADING_PAYMENT', false)
        },
        SET_BTN_TO({state, commit, dispatch}){
            if(state.btnNameFloat == 'Pilih Pembayaran' || state.btnNameFloat == 'Status') {
                commit('SET_IS_SHOW_DEFAULT', true)
            } else if(state.btnNameFloat == 'Bayar') {
                commit('SET_IS_LOADING_CHARGE', true)
                dispatch('CHARGE_ORDER_PAYMENT')
            }
        },
        async GET_LIST_PAYMENT_MOBILE({commit}, response) {
            
                // console.log(response.data)
            try{
                const data = response.data.reduce((acc , item, index, dataItem)=> {
                    if(!item.paymentMethod.hasChild && !item.paymentMethod.parentId) {
                        return [...acc, item]
                    } else if(item.paymentMethod.hasChild){
                        if(item.children) {
                            return [...acc, item]
                        } else {
                            return [...acc, {...item, children: []}]
                        }
                    } else if(item.paymentMethod.parentId) {
                      
                        const accParent = acc.findIndex(itemParent => itemParent.paymentMethod.id == item.paymentMethod.parentId)
                        if(accParent > 0) {
                            if(!acc[accParent].children) {
                                acc[accParent].children = [] 
                            }
                            acc[accParent].children.push(item)
                        } else {
                            const itemParent = dataItem.findIndex(itemParent1 => itemParent1.paymentMethod.id == item.paymentMethod.parentId)
                            if(!dataItem[itemParent].children) {
                                dataItem[itemParent].children = [] 
                            }
                            dataItem[itemParent].children.push(item)
                        }
                        return [...acc]
                    }  
                }, [])
                commit('SET_LIST_PAYMENT_MOBILE', data)
                // console.log(data)
            } catch {
                // console.log('error')
                // commit('SET_IS_GET_DATA', false)
            }
            
        },
        STORE_TO_CHILDREN({commit}, {name, children}) {
            const body = {
                name,
                children
            }
            commit('SET_LIST_CHILD_DATA', body)
        },
        CHEVRON_ACTION({commit, state}){
            commit('SET_IS_OPEN_FLOAT', !state.isOpenFloat)
            const bgGrey = document.getElementById("bg-backdrop");
            bgGrey.classList.toggle("d-none");
            const infoDokter = document.getElementById("info-dokter");
            infoDokter.classList.toggle("show-open");
            const chevron = document.getElementById("chevron-up");
            chevron.classList.toggle("rotate-chev");
        }
        // eslint-disable-next-line no-unused-vars
    },
    modules: {
    }
})
